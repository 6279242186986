import service from "./service"
import axios from 'axios';


/**
 * @description: 登录
 * @param  username:用户名 password:密码
 * @return 200 ok
 */
export function Login(username, password) {
	return service({
		url: "/api/v1/account/login",
		method: 'post',
		data: {
			username,
			password,
		}
	})
}


/**
 * @description: 注册
 * @param  username:用户名 password:密码 phone:电话号码
 * @return 200 ok
 */
export function Register(username, password, phone) {
	console.log('password:', password)
	return service({
		url: "/api/v1/account/register",
		method: 'post',
		data: {
			username,
			password,
			phone,
		}
	})
}

/**
 * @description: 创建任务
 * @param
 * @return 200 ok
 */
export function CreateTask(params) {
	return service({
		url: "/api/v1/task/create",
		method: 'post',
		data: params,
	})
}

/**
 * @description: - 获取进度
 * @return 数组
 */
export function QueryTaskResult(account_id, kind) {
	return service({
		method: 'get',
		url: '/api/v1/task/query',
		params: {
			account_id,
			kind
		}
	})
}

