/*
 * @Author: your name
 * @Date: 2022-06-08 19:56:54
 * @LastEditTime: 2022-06-17 14:40:26
 * @LastEditors: bogon
 * @Description: In User Settings Edit
 * @FilePath: /linkv-pc-meeting/src/renderer/http/http.js
 */
import axios from 'axios';
import Vue from 'vue'

const service = axios.create({
	// 设置baseUr地址,如果通过proxy跨域可直接填写base地址
	//baseURL: 'http://10.61.160.91:10088',
	baseURL: '',
	// 配置请求超时时间
	//timeout: 120000,
});

// 请求拦截
service.interceptors.request.use(config => {
	// 自定义header，可添加项目token
	return config;
});

// 返回拦截
service.interceptors.response.use(
	(response) => {
		// 获取接口返回结果
		const res = response.data;

		// code为0，直接把结果返回回去，这样前端代码就不用在获取一次data.
		if (res.code === 200) {
			return res;
		} else {
			return res;
		}
	},
	(error) => {
		console.log("error", error.response)
		Vue.prototype.$Message.error({
			content: '网络请求异常，请稍后重试!',
			duration: 2,
			closable: true
		});
		return Promise.reject(error);
	});

export default service;
